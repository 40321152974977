import { Text } from '@sitecore-jss/sitecore-jss-react';
import { compose } from 'ramda';
import React from 'react';

import {
  withDataSourceValidation,
  withEditMode,
  withSitecoreContext,
} from '_containers/BaseComponent';
import { default as StyledContentRichText } from '_components/ContentRichText/StyledContentRichText';
import { Grid } from '_utils/components';
import { normalizeJssFields } from '_utils/helpers/jss';
import {
  createSrcSet,
} from '_utils/media';
import { imagePlaceholderSrc } from '_utils/styles';
import { ImageSize } from '_utils/types/enums';

import { CampaignPageBannerValidators } from './validation';
import { CampaignPageBannerProps, NormalisedCampaignPageBannerFields } from './definitions';

import {
  BannerContent,
  BannerGridContainer,
  Container,
  BannerImageContainer,
  Image,
  BannerHeading,
  BannerDescription,
  BannerGradient,
  Video,
  ShadowContainer
} from './StyledCampaignPageBanner';

const CampaignPageBanner: React.FC<CampaignPageBannerProps> = ({
  rendering,
  editMode,
}) => {
  const normalisedDatasource: NormalisedCampaignPageBannerFields = normalizeJssFields(rendering?.fields?.data?.datasource) || {};
  const pageTitle = normalisedDatasource?.Heading;
  const pageDescription = normalisedDatasource?.Description;
  const pageImage = normalisedDatasource?.Image;
  const pageBannerBackgroundVideoLink = normalisedDatasource?.CampaignBannerBackgroundVideoLink;
  const uid = rendering?.uid

  return (
    <Container
      className="campaign-page-banner"
      data-component
      id={uid}
    >
      <BannerImageContainer>
        {
          pageBannerBackgroundVideoLink?.value?.href? (<Video id="background-video" loop autoPlay muted playsInline>
            <source src={pageBannerBackgroundVideoLink?.value?.href} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>)
          :
          (<Image
            className="lazyload"
            draggable={false}
            field={
              editMode
                ? pageImage
                : {
                  value: {
                    src: imagePlaceholderSrc,
                    alt: '', // alt text is empty string, as this is a decorative image
                    'data-srcset': createSrcSet(pageImage?.value?.src, [
                      ImageSize.MW_384,
                      ImageSize.MW_640,
                      ImageSize.MW_768,
                      ImageSize.MW_1024,
                      ImageSize.MW_1280,
                      ImageSize.MW_1664,
                    ])
                  }
                }
            }
            width="1280"
            height="720"
          />)
        }
      </BannerImageContainer>

      <ShadowContainer>
        <BannerGridContainer>
          <Grid.Row alignItems="stretch" config={{ gutters: [] }}>
            <BannerContent config={{ col: 12, gutters: [] }}>
              <BannerHeading>
                <Text field={pageTitle} />
              </BannerHeading>
              <BannerDescription>
                <StyledContentRichText field={pageDescription} className="fullWidthOnDesktop" />
              </BannerDescription>
            </BannerContent>
          </Grid.Row>
        </BannerGridContainer>
        <BannerGradient />
      </ShadowContainer>
    </Container>
  );
};

export default compose(
  withSitecoreContext(),
  withEditMode,
  withDataSourceValidation(CampaignPageBannerValidators, false)
)(CampaignPageBanner);
