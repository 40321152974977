import { canUseDOM } from 'exenv';
import { compose } from 'ramda';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { withEditMode, CoveoNoIndexWrapper } from '_containers/BaseComponent';

import HeadingWithDescription from '_utils/components/HeadingWithDescription';
import { useBreakpoint } from '_utils/hooks/useBreakpoint';

// TODO re-enable if needed
// import ShowMore from '_utils/components/ShowMore';

import { normalizeJssFields } from '_utils/helpers/jss';

import Item from './Item';
import SpotlightItem from './SpotlightItem';
import ItemsWrapper from './ItemsWrapper';
import {
  Container,
  Header,
  LinkButton,
  LinkWrapper,
  ListItem,
  ViewAllArrowWrapper,
  // ShowMoreContainer,
} from './StyledCardList';

import { CardListProps, ItemProps, simpleCardTypes } from './definitions';
import Icon from '_components/Icon';

let enquire;

if (canUseDOM) {
  enquire = require('enquire.js');
}

const CTAButton = ({ link, className, ctaButtonIcon }) => {
  const fallbackTitle = link?.fallbackTitle;
  const href = link?.value?.href || '';

  const renderIcon = () => {
    if (ctaButtonIcon) {
      return <Icon ariaHidden={true} name={ctaButtonIcon} />;
    }

    return href.startsWith('/') ? (
      <Icon ariaHidden={true} name="arrow_forward" />
    ) : (
      <Icon ariaHidden={true} name="open_in_new" />
    );
  };

  return (
    <LinkWrapper className={className}>
      <LinkButton field={link} fallbackTitle={fallbackTitle} noIcon={true}>
        <ViewAllArrowWrapper>
          {renderIcon()}
        </ViewAllArrowWrapper>
      </LinkButton>
    </LinkWrapper>
  );
};

const CardList: FC<CardListProps> = ({ rendering, editMode }) => {

  const [t] = useTranslation();
  const fields = rendering?.fields?.data?.datasource || {};
  const {
    params,
    uid,
  } = rendering;

  //-Default to Carousel because autopopulated card list should use it, and it doesn't have the option to choose List
  const layoutType = params?.layoutType || 'Carousel';

  // Check which type of podcast is using in "params.type"
  const podcastType = params?.type

  const normalized = normalizeJssFields(fields);
  const heading = normalized?.heading;
  const description = normalized?.description;
  const items: Array<ItemProps> = normalized?.items || [];
  const link = normalized?.link;
  const trackingName = normalized?.trackingName;
  const ctaButtonIcon = normalized?.ctaButtonIcon?.targetItem?.name || ''

  /* TODO re-enable if needed
  const numVisibleItems = params?.numVisibleItems?.value || 100; */
  const headingLevel = params?.headingLevel || 'h3';
  const validItems = !editMode ? items.filter((item) => item.link) : items;
  const simpleCardTypeItems = validItems.filter((item) => simpleCardTypes.includes(item.type));
  const hasItems = editMode || (validItems?.length > 0);

  validItems.sort((a,b)=> {
    if (a.type === 'categoryLarge') {
      return -1; // a comes before b
    }
    if (b.type === 'categoryLarge') {
      return 1; // b comes before a
    }
    return 0; // no change in ordering
  })
  

  /* TODO re-enable if needed
  const [canShowMore, setCanShowMore] = useState(items.length > numVisibleItems);
  const [showingMore, setShowingMore] = useState(false);
  const itemsToShow = showingMore ? items : validItems.slice(0, numVisibleItems);
  const evenItemsClass = validItems && validItems.length % 2 === 0 ? 'is-even-items' : '';
  const showingAllClass = !canShowMore || showingMore ? 'is-showing-all' : '';
  const singleItemClass = itemsToShow.length === 1 ? 'is-single-item ' : '';*/

  // carousel functionality
  // define how many cards required to trigger carousel behaviour
  const bp = useBreakpoint();
  const numValidItemsToRenderCarousel = {
    xs: 2,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4,
  };

  const carouselEnabled = !editMode && canUseDOM && layoutType === 'Carousel' && validItems?.length >= numValidItemsToRenderCarousel[bp];
  const allCardTypeSimple = simpleCardTypeItems?.length == validItems?.length;

  // nested repsonsive behaviour
  const multiColumnSettings = {
    md: {
      1: 12,
      2: 6,
      multi: 6
    },
    lg: {
      1: 12,
      2: 6,
      // if all card types are simple, render as 4 column, else render as 3 column
      multi: allCardTypeSimple && validItems?.length > 3 ? 3 : 4
    },
    xl: {
      1: 12,
      2: 6,
      // if all card types are simple, render as 4 column, else render as 3 column
      multi: allCardTypeSimple && validItems?.length > 3 ? 3 : 4
    },
  };

  // Change to let type so to override it in later code
  let responsiveColumnConfig = 
    // nested behaviour
    !carouselEnabled ?
      {
        col: {
          xs: 12,
          md: multiColumnSettings.md[validItems.length < 3 ? validItems.length : 'multi'],
          lg: multiColumnSettings.lg[validItems.length < 3 ? validItems.length : 'multi'],
          xl: multiColumnSettings.lg[validItems.length < 3 ? validItems.length : 'multi'],
        }
      }
      :
      // carousel behaviour
      {
        col: {
          xs: 10,
          md: 5,
          lg: 4,
          xl: 4,
        }
      }
    ;

  const categoryLargeCardConfig = {
    col: {
      xs: 12,
      md: 12,
      lg: 8,
      xl: 8,
    }
  }
  /* TODO re-enable if needed
  useEffect(() => {
    setCanShowMore(validItems.length > numVisibleItems && !showingMore);
  }, [validItems, showingMore, numVisibleItems]); */

  const level = parseInt(headingLevel?.toLowerCase().replace('h', '')) || 3;
  const shouldShowCTA: boolean = !!(editMode || (link && link?.value?.href));

  const classes = [];

  if (validItems.length === 1 && !carouselEnabled) {
    classes.push('fullwidth');
  }
 
  // Check the podcastcard type and then Override the css configuration above
  if(podcastType === 'RemoveGroupByYear' || podcastType === 'PodcastBrandPage') {
    responsiveColumnConfig =  {
      col: {
        xs: 12,
        md: multiColumnSettings.md['multi'],
        lg: multiColumnSettings.lg['multi'],
        xl: multiColumnSettings.lg['multi'],
      }
    }
    // Since classes is a const type array, set the lenght to 0 will clear all its content in side
    classes.length = 0
  } 

  return (
    <CoveoNoIndexWrapper editMode={editMode}>
      {(editMode || hasItems) &&
        <Container
          numItems={validItems?.length ?? 0}
          className="card-list"
          data-component
          id={uid}
        >
          {(heading || description) && (
            <Header>
              <HeadingWithDescription
                headingField={heading}
                descriptionField={description}
                headingLevel={headingLevel}
              >
              {shouldShowCTA && <CTAButton link={link} className="hideOnMobile" ctaButtonIcon={ctaButtonIcon}/>}
              </HeadingWithDescription>
            </Header>
          )}

          {hasItems &&
            <ItemsWrapper trackingName={trackingName?.value} carouselEnabled={carouselEnabled} className={classes.join(' ')} /*${evenItemsClass} ${showingAllClass}`}*/>
              {validItems.map((item, index) => (
                <ListItem
                  // className={`${singleItemClass} ${!editMode && !itemsToShow.includes(item) ? 'is-hidden' : ''}`}
                  key={item.id || index}
                  as={carouselEnabled ? '' : 'li'}
                  carouselEnabled={carouselEnabled}
                  config={item.type ==='categoryLarge' ? categoryLargeCardConfig: responsiveColumnConfig}
                  editMode={editMode}
                >
                {item.type === 'categoryLarge' ? <SpotlightItem {...item} editMode={editMode} headingLevel={level} /> : <Item {...item} editMode={editMode} headingLevel={level} />}
                </ListItem>
              ))}
            </ItemsWrapper>
          }
          {shouldShowCTA && <CTAButton link={link} className="showOnMobile" ctaButtonIcon={ctaButtonIcon}/>}
          {/* TODO re-enable if required
            {canShowMore && (
            <ShowMoreContainer>
                <ShowMore
                  label={footerLinkLabel?.value || t('related-content-default-show-more-label')}
                  title={t('related-content-accessible-more-items-label')}
                  count={validItems.length - numVisibleItems}
                  onClick={() => {
                    setShowingMore(true);
                    setCanShowMore(false);
                  }}
                />
              </ShowMoreContainer>
            )} */}
        </Container>
      }
    </CoveoNoIndexWrapper>
  );
};

export default compose(withEditMode)(CardList);
