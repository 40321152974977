import { Image as SCImage } from "@sitecore-jss/sitecore-jss-react";
import styled from "styled-components";

import { Grid } from "_utils/components";
import { spacing } from "_utils/props";
import { headingH1, hideOnPrint, mq, subheading1 } from "_utils/styles";

const BANNER_MIN_HEIGHT_XS = 396;
const BANNER_MIN_HEIGHT_MD = 480;
const BANNER_MIN_HEIGHT_LG = 590;

export const Container = styled.div`
  align-items: flex-end;
  background-color: ${({ theme }) =>
    theme.campaignPageBanner.container.background};
  display: flex;
  min-height: ${BANNER_MIN_HEIGHT_XS}px;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${mq("md")} {
    min-height: ${BANNER_MIN_HEIGHT_MD}px;
  }

  ${mq("lg")} {
    min-height: ${BANNER_MIN_HEIGHT_LG}px;
  }

  @media print {
    background-color: ${({ theme }) =>
      theme.campaignPageBanner.container.mediaPrint.background};
    color: ${({ theme }) =>
      theme.campaignPageBanner.container.mediaPrint.color};
    min-height: 100%;
  }
`;

export const ShadowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`

export const BannerGridContainer = styled(Grid.Container)`
  position: relative;
  z-index: 1;
  padding-top: 0 !important;
  ${({ theme }) =>
    spacing({
      theme,
      px: {
        xs: "20px",
        md: 3,
        lg: 4,
        xl: 0,
      },
      py: {
        xs: 5,
        md: 6,
        lg: 7,
      },
      pt: {
        xs: 3,
        md: 3,
        lg: 0
      }
    })};
`;

export const BannerImageContainer = styled.figure`
  display: block;
  height: 100%;
  margin: 0;
  user-select: none;

  ${hideOnPrint}
`;

export const Image = styled(SCImage)`
  font-family: "object-fit: cover;";
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Video = styled.video`
  font-family: "object-fit: cover;";
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const BannerContent = styled(Grid.Item)`
  @media print {
    ${({ theme }) => spacing({ theme, py: 3 })};
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const BannerHeading = styled.h1`
  ${headingH1}
  color: ${({ theme }) => theme.campaignPageBanner.bannerHeading.color};
  word-break: break-word;
  margin-bottom: 0;

  /* mq specificity required to override default heading margin-bottom */
  ${mq("md")} {
    margin-bottom: 0;
  }
`;

export const BannerDescription = styled.h2`
  color: ${({ theme }) => theme.campaignPageBanner.container.color};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
  margin-top: 16px;

  /* mq specificity required to override default heading margin-bottom */
  ${mq("md")} {
    ${subheading1}
    margin-bottom: 0;
    margin-top: 16px;
  }
`;

export const BannerGradient = styled.div`
  /* https://larsenwork.com/easing-gradients */
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 46.53%);
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  user-select: none;
  width: 100%;
`;
